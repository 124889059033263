import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const ENABLE = process.env.NODE_ENV === "production";

export function initSentry({ dsn }) {
	if (!ENABLE) {
		return;
	}
	Sentry.init({
		dsn,
		integrations: [new Integrations.BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}
