function supportsHLS() {
	var video = document.createElement("video");
	return Boolean(
		video.canPlayType("application/vnd.apple.mpegURL") ||
			video.canPlayType("audio/mpegurl")
	);
}

export const SUPPORTS_HLS = supportsHLS();

// https://github.com/Dash-Industry-Forum/dash.js/issues/2055#issuecomment-313187528
export const SUPPORTS_DASH =
	typeof (window.MediaSource || window.WebKitMediaSource) === "function";
