import ReactGA from "react-ga";

const ENABLE = process.env.NODE_ENV === "production";

export function initAnalytics({ trackingId, history }) {
	if (!ENABLE) {
		return;
	}
	ReactGA.initialize(trackingId);
	ReactGA.pageview(window.location.pathname + window.location.search);
	if (history) {
		history.listen((location, action) => {
			ReactGA.pageview(location.pathname + location.search);
		});
	}
}

export function webVitalsEvent({ id, name, value }) {
	event({
		category: "Web Vitals",
		action: name,
		label: id,
		value: Math.round(name === "CLS" ? value * 1000 : value),
		nonInteraction: true,
	});
}

export function event({ category, action, label, value, nonInteraction }) {
	if (!ENABLE) {
		console.info("Analytics event", { category, action, label, value });
	} else {
		try {
			ReactGA.event({
				category,
				action,
				label,
				value,
				nonInteraction,
			});
		} catch (err) {
			// nothing
		}
	}
}

export function timing({ category, variable, value, label }) {
	if (!ENABLE) {
		// console.info("Analytics timing", { category, variable, value, label });
	} else {
		try {
			ReactGA.timing({
				category,
				variable,
				value,
				label,
			});
		} catch (err) {
			// nothing
		}
	}
}
